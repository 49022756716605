import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// slices
import userReducer from './slices/user'
import authJwtReducer from './slices/authJwt/authJwt'
import aichart from './slices/aichart'
import settingsReducer from './slices/settings'
import companyReducer from './slices/company'
import filtersReducer from './slices/filters'
import initiativeReducer from './slices/initiative'
import impactReducer from './slices/impact'
import reportsReducer from './slices/reports'
import manageReducer from './slices/manage'
import paymentsReducer from './slices/payments'
import insightsReducer from './slices/insights'

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['settings'],
}

const authPersistConfig = {
    key: 'authJwt',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['isAuthenticated'],
}

const aiChartPersistConfig = {
    key: 'aichart',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['messages'],
}

const insightsPersistConfig = {
    key: 'insights',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['initiativeAIData', 'settingsAIData'],
}


const appReducer = combineReducers({
    user: userReducer,
    settings: settingsReducer,
    company: companyReducer,
    manage: manageReducer,
    filters: filtersReducer,
    initiative: initiativeReducer,
    payments: paymentsReducer,
    impact: impactReducer,
    reports: reportsReducer,
    insights: persistReducer(insightsPersistConfig, insightsReducer),
    aichart: persistReducer(aiChartPersistConfig, aichart),
    authJwt: persistReducer(authPersistConfig, authJwtReducer),
})

// @ts-ignores
const rootReducer = (state, action) => {
    if (action.type === 'authJwt/logoutSuccess') {
        // for all keys defined in your persistConfig(s)
        Promise.all([
            storage.removeItem('persist:root'),
            storage.removeItem('persist:authJwt'),
            storage.removeItem('persist:aichart'),
            storage.removeItem('persist:insights'),
        ])
        sessionStorage.clear()
        localStorage.clear()
        // storage.removeItem('persist:otherKey')
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export { rootPersistConfig, rootReducer }
