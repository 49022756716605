import { createSlice } from '@reduxjs/toolkit'
import axiosInstance, { getSandboxInstance, responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isSandBox } from '../settings'
import { addMinutes, endOfDay, format, parseISO, startOfDay } from 'date-fns'
import { reportError } from 'utils/errorReport'
import { RootState } from 'store'
import axios from 'axios';
import { createSelector } from 'reselect';
import { impactTotalTiming, parseFromToDateObject, parseImpactTimingString } from 'utils/date';
import {
    ChangeToleranceLevel,
    ImpactList,
    OCLLevel,
    OrganizationLevel,
    ResourceLevel
} from '../../../@types/initiative';
import { getXAxisDataPeriod } from 'views/reports/Heatmaps/utils';
import { getImpactLevelsTree } from 'store/slices/company';
import { ImpactTiming } from '../../../@types/impact';
import { impactWorkingHours } from 'utils/iniatives';
import { isString } from 'lodash'


interface TotalImpactReportInterface {
    isLoading: boolean,
    error: boolean,
    data: Record<string, any>,
    selectedImpacts: Record<string, any>[]
    highlightedIndex: number
    filtersData: {
        initiatives: any[],
        companyStrategy: any[],
        phase: any[],
        owner: any[],
        changeType: any[],
        activities: any[],
        stakeholders: any[],
        contact: any[],
        partnerStakeholders: any[],
        partnerImpactScales: any[]
    }
}

const initialState: TotalImpactReportInterface = {
    isLoading: false,
    error: false,
    data: {},
    selectedImpacts: [],
    highlightedIndex: -1,
    filtersData: {
        initiatives: [],
        companyStrategy: [],
        phase: [],
        owner: [],
        changeType: [],
        activities: [],
        stakeholders: [],
        contact: [],
        partnerStakeholders: [],
        partnerImpactScales: []
    }
}

const slice = createSlice({
    name: 'totalImpact',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
            state.error = false
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getTotalImpactSuccess(state, action) {
            state.isLoading = false
            state.data = action.payload
        },

        setTotalImpactReportSelectedImpacts(state, action) {
            state.selectedImpacts = action.payload
        },

        setTotalImpactFilterData(state, action) {
            state.filtersData = action.payload
        },
        setHighlightedIndex(state, action) {
            state.highlightedIndex = action.payload
        }
    },
})

export default slice.reducer

export const { setTotalImpactReportSelectedImpacts, setHighlightedIndex } = slice.actions

export const getTotalImpactReportSelectedImpacts = createSelector(
    (state: RootState) => state.reports.totalImpactChart,
    (totalImpactChart): Record<string, any>[] => totalImpactChart.selectedImpacts
)

export const getTotalImpactReportSelectedIndex = createSelector(
    (state: RootState) => state.reports.totalImpactChart,
    (totalImpactChart): number => totalImpactChart.selectedIndex
)

let totalImpactAxiosSource = axios.CancelToken.source()

export function getTotalImpactChart(startDate: Date | null, endDate: Date | null) {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(slice.actions.getTotalImpactSuccess({}))
        dispatch(slice.actions.startLoading())
        dispatch(slice.actions.setTotalImpactReportSelectedImpacts([]))
        dispatch(slice.actions.setHighlightedIndex(-1))
        totalImpactAxiosSource.cancel()
        totalImpactAxiosSource = axios.CancelToken.source()

        try {
            const response = await (isSandBox(getState()) ? await getSandboxInstance() : axiosInstance).get('/api/Report/ReportTotalImpact', {
                params: {
                    impactFromDate: format(startDate || new Date(), 'y-MM-d'),
                    impactToDate: format(endDate || new Date(), 'y-MM-d'),
                    useDefaultDiv: false,
                },
                cancelToken: totalImpactAxiosSource.token
            })
            const data = responseData(response.data)
            const impactLevels = getImpactLevelsTree(getState())

            const initiatives = new Set()
            const companyStrategy = new Set()
            const phase = new Set()
            const owner = new Set()
            const changeType = new Set()
            const activities = new Set()
            const stakeholders = new Set()
            const partnerStakeholders = new Set()
            const contact = new Set()
            const partnerImpactScales = new Set()


            const impactData = (data?.impactData || []).map((impact: Record<string, any>) => {
                initiatives.add(impact.Initiatives)
                impact.CompanyStrategy?.forEach(companyStrategy.add.bind(companyStrategy))
                phase.add(impact.Phase)
                owner.add(impact.Owner)
                impact.ChangeType.forEach(changeType.add.bind(changeType))
                contact.add(impact.Contact)
                impact.Activities.forEach(activities.add.bind(activities))
                impact.Stakeholders.forEach(stakeholders.add.bind(stakeholders))
                partnerImpactScales.add(impact?.PartnerImpactScaleId)
                impact.PartnerStakeholder?.forEach(partnerStakeholders.add.bind(partnerStakeholders))
                //const workingHours = impactWorkingHours(impact, impactLevels)

                const timing = parseImpactTimingString(impact.Periods)
                return {
                    ...impact,
                    Timing: timing,
                    ImpactHours: impact.ImpactHours || impact.WeeklyHours,
                    pFrom: isString(impact.From) ? parseISO(impact.From) : impact.From,
                    pTo: isString(impact.To) ? parseISO(impact.To) : impact.To,
                    range: getXAxisDataPeriod(impact),
                }
            })

            dispatch(slice.actions.setTotalImpactFilterData({
                initiatives: Array.from(initiatives).sort(),
                companyStrategy: Array.from(companyStrategy).sort(),
                phase: Array.from(phase).sort(),
                owner: Array.from(owner).sort(),
                changeType: Array.from(changeType).sort(),
                activities: Array.from(activities).sort(),
                stakeholders: Array.from(stakeholders).sort(),
                contact: Array.from(contact).sort(),
                partnerStakeholders: Array.from(partnerStakeholders).sort(),
                partnerImpactScales: Array.from(partnerImpactScales).filter((i) => i),
            }))
            dispatch(
                slice.actions.getTotalImpactSuccess({
                    ...data,
                    EmployeeChangeTolerance: (data?.EmployeeChangeTolerance||[]).map((i: Record<string, any>) => parseFromToDateObject(i) as ChangeToleranceLevel),
                    EmployeeResourcingLevel: (data?.EmployeeResourcingLevel||[]).map((i: Record<string, any>) => parseFromToDateObject(i) as ResourceLevel),
                    EmployeeOCLLevel: (data?.EmployeeOCLLevel||[]).map((i: Record<string, any>) => parseFromToDateObject(i) as OCLLevel),
                    PartnerOCLLevel: (data?.PartnerOCLLevel||[]).map((i: Record<string, any>) => parseFromToDateObject(i) as OrganizationLevel),
                    PartnerChangeTolerance: (data?.PartnerChangeTolerance||[]).map((i: Record<string, any>) => parseFromToDateObject(i) as OrganizationLevel),
                    impactData
                })
            )
        } catch (error) {
            if (axios.isCancel(error)) {
                return;
            }
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}


export const getTotalImpactFiltersData = createSelector(
    (state: RootState) => state.reports.totalImpactChart,
    (totalImpactChart): Record<string, any> => totalImpactChart.filtersData
)


export const changeTotalImpactItem = (impact: ImpactList) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
        const reportData = getState().reports.totalImpactChart
        const impactLevels = getImpactLevelsTree(getState())
        //
        const mapFn = ((item: Record<string, any>) => {
            if (item.ImpactId === impact.Id) {
                const Periods = JSON.stringify(impact.Timing)
                const timing = parseImpactTimingString(Periods)
                //console.log('changeImpactItem', impact.Timing)
                const totalTiming = impactTotalTiming(timing as ImpactTiming)
                const workingHours = impactWorkingHours(impact, impactLevels)
                return {
                    ...item,
                    ChangeImpactName: impact.Name,
                    Timing: timing,
                    range: getXAxisDataPeriod({ ...item, Periods }),
                    From: addMinutes(startOfDay(totalTiming.From), 10),
                    To: addMinutes(endOfDay(totalTiming.To), -10),
                    ImpactHours: workingHours,
                    WorkingHours: workingHours,
                    ImpactLevel: impact.Level,
                }
            }
            return item
        })
        dispatch(slice.actions.getTotalImpactSuccess(
            { ...( reportData.data || {}), impactData: (reportData.data?.impactData || []).map(mapFn) }
        ))
        dispatch(slice.actions.setTotalImpactReportSelectedImpacts(reportData.selectedImpacts.map(mapFn)))
    }
}