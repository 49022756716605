import React, { useState } from 'react'
import { Box, Drawer, IconButton, Typography } from '@mui/material'

import ChatIcon from '@mui/icons-material/Chat';
import useAuth from 'hooks/useAuth';
import Scrollbar from '../../components/Scrollbar'
import { GridContainer, GridItem } from '../../components/GridElements'
import CloseIcon from '@mui/icons-material/Close'
import AIChatContent from '../../components/AiChart'

const AiChartPopover = () => {

    const [showChat, setShowChat] = useState(false);

    const { user, isCompanyAdmin } = useAuth()

    if (!isCompanyAdmin && user?.email !== 'company@admin.com') {
        return (<></>)
    }

    return (
        <>
            <IconButton
                sx={{ float: 'right', mb: '0px', cursor: 'pointer' }}
                title={'Chat Assitant'}
                onClick={(e:any) => setShowChat(!showChat)}>
                <ChatIcon />
            </IconButton>

            <Drawer
                open={showChat}
                anchor='right'
                onClose={() => setShowChat(!showChat)}
                sx={{ zIndex: 1999 }}
                PaperProps={{
                    sx: { minWidth: '20vw', maxWidth: '50vw', height: '100vh' },
                }}
            >
                <Scrollbar sx={{ height: '100vh' }} forceVisible={true}>
                    <GridContainer>
                        <GridItem>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                                <ChatIcon /> &nbsp;&nbsp;
                                <Typography variant={'subtitle1'}> Chat Assistant</Typography>
                            </Box>

                        </GridItem>
                        <GridItem xs={2}>
                            <IconButton
                                sx={{ position: 'relative', float: 'right' }}
                                title={'Close Insights'}
                                onClick={() => setShowChat(!showChat) }>
                                <CloseIcon />
                            </IconButton>
                        </GridItem>
                    </GridContainer>
                    <AIChatContent />
                </Scrollbar>
            </Drawer>
        </>
    )
}

export default AiChartPopover