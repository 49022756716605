// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
    return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_INITIATIVES = '/initiatives'
const ROOTS_REPORTS = '/reports'
const ROOTS_SETTINGS = '/settings'
const ROOT_GUIDES = '#'
const ROOTS_SETUP = path(ROOTS_SETTINGS, '/setup')
const ROOTS_INTEGRATION = path(ROOTS_SETTINGS, '/integrations')
const ROOTS_SUPERADMIN = '/manage'
const ROOTS_DASHBOARD_HOME = '/dashboard/home'
const ROOTS_ACTION_PLANING = '/action/planing'
const ROOTS_AUDIT_PLANING = '/audit'
const ROOTS_PREDICTION = '/prediction'
const ROOTS_ANALYTICS = '/analytics'

// ----------------------------------------------------------------------
export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register/:planId?'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    changePassword: path(ROOTS_AUTH, '/reset'),
    verify: path(ROOTS_AUTH, '/verify'),
    subscribe: path(ROOTS_AUTH, '/subscribe'),
    paymentDetails: path(ROOTS_AUTH, '/payment/details'),
    completed: path(ROOTS_AUTH, '/completed'),
    logout: path(ROOTS_AUTH, '/logout'),
    saml: path('/', '/saml'),
    samlSignIn: path('/', '/umm/api/account/sign-saml'),
}

export const PATH_HOME = {
    cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
    purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
    components: '/components',
    dashboard: ROOTS_DASHBOARD_HOME,
}

export const PATH_GUIDES = {
    root: ROOT_GUIDES,
    home: ROOT_GUIDES,
    settings: {

    }
}

export const PATH_SETTINGS = {
    root: ROOTS_SETTINGS,
    home: ROOTS_SETTINGS,
    settings: {
        profile: path(ROOTS_SETTINGS, '/profile'),
        hierarchy: path(ROOTS_SETTINGS, '/hierarchy'),
        users: path(ROOTS_SETTINGS, '/users'),
        integration: ROOTS_INTEGRATION,
        integrationJira: path(ROOTS_INTEGRATION, '/jira'),
        subscription: path(ROOTS_SETTINGS, '/subscriptions'),
        // setups
        setup: ROOTS_SETUP,
        setupInitiative: path(ROOTS_SETUP, '/Initiative'),
        setupEmployeeImpact: path(ROOTS_SETUP, '/EmployeeImpact'),
        setupOtherImpact: path(ROOTS_SETUP, '/OtherImpact'),
        setupPartnerImpact: path(ROOTS_SETUP, '/PartnerImpact'),
        setupImpactAid: path(ROOTS_SETUP, '/ImpactAid'),
        setupBenefit: path(ROOTS_SETUP, '/Benefit'),
        setupTotalImpactReport: path(ROOTS_SETUP, '/TotalImpactReport'),
        setupScenario: path(ROOTS_SETUP, '/Scenario'),
        setupNotifications: path(ROOTS_SETUP, '/Notifications'),
    },
}

export const PATH_SUPERADMIN = {
    root: ROOTS_SUPERADMIN,
    home: ROOTS_SUPERADMIN,
    manage: {
        dashboard: path(ROOTS_SUPERADMIN, '/dashboard'),
        buglist: path(ROOTS_SUPERADMIN, '/buglist'),
        notifications: path(ROOTS_SUPERADMIN, '/notifications'),
        companies: path(ROOTS_SUPERADMIN, '/companies'),
        users: path(ROOTS_SUPERADMIN, '/users'),
        editCompanies: path(ROOTS_SUPERADMIN, '/companies/:companyId'),
    },
}

export const PATH_REPORTS = {
    root: ROOTS_REPORTS,
    home: ROOTS_REPORTS,
    reports: {
        heatmaps: path(ROOTS_REPORTS, '/heatmaps'),
        totalImpact: path(ROOTS_REPORTS, '/TotalImpact'),
        timelineChart: path(ROOTS_REPORTS, '/TimeLineChart'),
        executiveSummary: path(ROOTS_REPORTS, '/ExecutiveSummary'),
        bubblechart: path(ROOTS_REPORTS, '/bubbles'),
        strategyimpact: path(ROOTS_REPORTS, '/strategyimpact'),
        speedometer: path(ROOTS_REPORTS, '/speedometer'),
        stakeholderChart: path(ROOTS_REPORTS, '/stakeholderchart'),
        resourcingChart: path(ROOTS_REPORTS, '/resourcing'),
        readinessChart: path(ROOTS_REPORTS, '/readiness'),
        radarChart: path(ROOTS_REPORTS, '/radar'),
        changeCalendarChart: path(ROOTS_REPORTS, '/changecalendar'),
        benefitsChart: path(ROOTS_REPORTS, '/benefits'),
    },
}

export const PATH_INITIATIVES = {
    root: ROOTS_INITIATIVES,
    home: ROOTS_INITIATIVES,
    actions: {
        history: path(ROOTS_INITIATIVES, '/history/:initiativeId'),
        newItem: path(ROOTS_INITIATIVES, '/wizard'),
        roadmap: path(ROOTS_INITIATIVES, '/roadmap'),
        addEdit: path(ROOTS_INITIATIVES, '/wizard/:initiativeId'),
        addEditWithStep: path(ROOTS_INITIATIVES, '/wizard/:initiativeId/:stepName'),
        addEditImpactWithStep: path(ROOTS_INITIATIVES, '/wizard/:initiativeId/impact/:impactId'),
    },
}

export const PATH_ACTION_PLANING = {
    root: ROOTS_ACTION_PLANING,
    home: ROOTS_ACTION_PLANING,
}

export const PATH_AUDIT_PLANING = {
    root: ROOTS_AUDIT_PLANING,
    home: ROOTS_AUDIT_PLANING,
}

export const PATH_PREDICTION = {
    root: ROOTS_PREDICTION,
    home: ROOTS_PREDICTION,
}

export const PATH_ANALYTICS = {
    root: ROOTS_ANALYTICS,
    home: ROOTS_ANALYTICS,
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    home: ROOTS_DASHBOARD_HOME,
    general: {
        dashboard: path(ROOTS_DASHBOARD, '/home'),
    },
}
