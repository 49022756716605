import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance, { responseData } from 'utils/axios'
import { Dispatch } from 'redux'
import { isEmpty } from 'lodash'
import { AIDGroup, AIDGroupGrouped, ImpactAid } from '../../../@types/impact'
import { createSelector } from 'reselect';
import { RootState } from 'store/store';


const aidType = (aidType: number): string => {
    switch (aidType) {
        case 1:
            return 'low'
        case 2:
            return 'medium'
        case 3:
            return 'high'
        default:
            return 'unknown'
    }
}

interface initialStateProps {
    isLoading: boolean,
    saving: boolean,
    error: Record<string, any> | null,
    aids: AIDGroup[]
}

const initialState: initialStateProps = {
    isLoading: false,
    saving: false,
    error: null,
    aids: []
}

const slice = createSlice({
    name: 'aids',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        stopLoading(state) {
            state.isLoading = false
        },

        setSaving(state, action) {
            state.saving = action.payload
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        consumeError(state) {
            state.error = initialState.error
        },

        // GET PRODUCTS
        getAidsSuccess(state, action) {
            state.isLoading = false
            state.aids = action.payload
        },

        getAidSuccess(state: any, action) {
            state.isLoading = false
            state.aids[aidType(action.payload.Type)] = action.payload
        },
    },
})

export default slice.reducer

export const consumeError = () => (dispatch: Dispatch<any>) => dispatch(slice.actions.consumeError())


export const hasEnabledAIDS = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): boolean => aids.some((aid: AIDGroup) => aid.Enable)
)

export const allAids = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): AIDGroup[] => aids
)

export const hasImpactAids = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): boolean => isEmpty(aids)
)

export const impactAidError = createSelector(
    (state: RootState) => state.impact.aids.error,
    (error): Record<string, any> | null => error
)

export const getAidsTree = createSelector(
    (state: RootState) => state.impact.aids.aids,
    (aids): Record<string, AIDGroupGrouped>  => {
        const defaultGrouped = {high: null, medium: null, low: null }
        let grouped =  { 'aids': { ...defaultGrouped }, } as Record<string, AIDGroupGrouped>
        aids.forEach((aid: AIDGroup) => {
            if (isEmpty(aid.TypeIds)) {
                grouped['aids'] = { ...(grouped['aids'] || { ...defaultGrouped }), [aidType(aid.Type)]: aid }
                return
            }
            aid.TypeIds.forEach((typeId: string) => {
                grouped[typeId] = { ...(grouped[typeId] || { ...defaultGrouped }), [aidType(aid.Type)]: aid }
            })
        })
        console.log('grouped', grouped, aids)
        return grouped
    }
)


export const fetchAids = createAsyncThunk(
    'thunk/getFetchAids',
    async (_, { dispatch }) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.get('/api/CompanySettings/GetAllImpactAid?request=d')
            const data: AIDGroup[] = responseData(response.data)
            dispatch(slice.actions.getAidsSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.stopLoading())
        }
    })

/*export function getAids() {
    return async (dispatch: Dispatch<any>) => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axiosInstance.get('/api/CompanySettings/GetAllImpactAid?request=d')
            const data: AIDGroup[] = responseData(response.data)

            const aids = keyBy(data, (group: AIDGroup) => aidType(group.Type))
            // save only one? or all?
            /*if (byType === undefined) dispatch(slice.actions.getAidsSuccess(aids))
            else {
                const toSave = aids[aidType(byType)]

                if (toSave === undefined) throw new Error(`Could not get ${aidType(byType)} Impact Aid`)
                else dispatch(slice.actions.getAidSuccess(toSave))
            }s
        } catch (error) {
            reportError(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}*/

export const saveAid = async (item: AIDGroup): Promise<any> => {
    const { Enable, ScaleIds, Id, Type, TypeIds } = item
    const { data } = await axiosInstance.post('/api/CompanySettings/SaveImpactAidGroup?request=d', { Enable, ScaleIds, Id, Type, TypeIds })
    const id = isEmpty(data?.responseData) ? Id : data?.responseData
    await Promise.all(item.ImpactAids.map((i: any) => axiosInstance.post('/api/CompanySettings/SaveImpactAid?request=d', [{ ...i, ImpactAidGroupId: id }])))
}


export const deleteAidItem = async (item: ImpactAid) => {
    if (isEmpty(item.Id)) return
    await axiosInstance.post('/api/CompanySettings/DeleteImpactAid?request=d&Id=' + item.Id, {})
}

export const deleteAidGroup = async (item: AIDGroup) => {
    if (isEmpty(item.Id)) return
    await axiosInstance.post('/api/CompanySettings/DeleteImpactAidGroup?request=d&Id=' + item.Id, {})
}
