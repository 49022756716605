import React, { useEffect, useState } from 'react'
import ChatMessageInput from './ChatMessageInput'
import { Box, Divider } from '@mui/material'
import ChatMessageList from './ChatMessageList'
import BouncingDotsLoader from 'components/BouncingDotsLoader'

import useClosableSnackbar from '../../hooks/useClosableSnackbar'

import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux'
import { aiMessages, askAIRequest } from '../../store/slices/aichart'


const AIChatContent = () => {

    const dispatch = useAppDispatch()

    const [isAsking, setIsAsking] = useState<boolean>(false)
    const ref = React.useRef<HTMLHRElement|null>(null)
    const conversations = useAppSelector(aiMessages)

    const { errorEnqueueSnackbar } = useClosableSnackbar()

    useEffect(() => {
        const scrollMessagesToBottom = () => {
            if (ref.current) {
                ref.current.scrollIntoView();
            }
        };
        scrollMessagesToBottom()
    }, [conversations])

    const handleSendMessage = (message: string) => {
        setIsAsking(true)

        dispatch(askAIRequest(message)).unwrap().then(() => {
            /// pass
        })
            .catch((err: any) => {
                reportError(err)
                errorEnqueueSnackbar('Failed to get response')
            })
            .finally(() => {
                setIsAsking(false)
                if (ref.current) {
                    ref.current.scrollIntoView()
                }
            })

    }

    return (
        <>
            <ChatMessageList conversation={conversations} />
            {
                isAsking && (<BouncingDotsLoader style={{ height: '20px', justifyContent: 'flex-start' }} />)
            }
            <Divider sx={{ m: 0, p: 0 }}  />
            <ChatMessageInput
                conversationId={'ai'}
                onSend={handleSendMessage}
                disabled={isAsking}
            />
            <Box ref={ref} ></Box>
        </>
    )
}

export default AIChatContent